<template>
  <Navigation />
  <div>
    <center>
      <h1>404 page not found</h1>
      <h2>it seems you're in the wrong page</h2>
    </center>
  </div>
</template>
<script>
import Navigation from "@/views/Navigation";

export default {
  components: {
    Navigation,
  },
};
</script>
<style></style>
